import React from "react";
import { BsFiletypeCsv } from "react-icons/bs";
import styled from "styled-components";

interface Props {
  type: string;
  startDate?: string;
  endDate?: string;
}

export const AppExportCsv = React.memo((props: Props) => {
  const { type, startDate, endDate } = props;
  const apiUrl = process.env.REACT_APP_API_URL?.replace(/\/+$/, '');  // Remove trailing slashes
  const normalizedType = type.replace(/^\/+/, '');                    // Remove leading slashes
  const exportUrl = (startDate && endDate) ? `${apiUrl}/api/${normalizedType}Csv/export?startDate=${startDate}&endDate=${endDate}` : `${apiUrl}/${normalizedType}/export`;
  //✓
  return (
    <a
      href={exportUrl}
      style={{ textDecoration: "none", width: "fit-content" }}
    >
      <Wrap>
        <BsFiletypeCsv className="icon" size={24} />
        <p className="text">出力</p>
      </Wrap>
    </a>
  );
});

const Wrap = styled.div`
    display: flex;
    font-weight: 700;
    border: 2px solid orange;
    color: orange;
    border-radius: 10px;
    display: flex;
    align-items: center;
    width: fit-content;
    padding: 8px 10px;
    :hover {
        cursor: pointer;
        color: #d88d02;
        border: 2px solid #d88d02;
        .icon {
            fill: #d88d02;
        }
    }
    .icon {
        fill: orange;
    }
    .text {
        margin-left: 1rem;
    }
`;
