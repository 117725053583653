import React from "react";

import { StyledInventoryItem } from ".";
import layer from "@assets/Icons/layer.svg";
import { useHistory, useLocation } from "react-router-dom";
import { linkProductImage } from "@utils";

interface IProps {
    item?: any;
}
export const InventoryItemComponent = (props: IProps) => {
    // props
    const { item } = props;

    // hooks
    const useQuery = () => new URLSearchParams(useLocation().search);
    const query = useQuery();
    const categoryId = query.get("categoryId");
    const subCategoryId = query.get("subCategoryId");
    const history = useHistory();

    const imageSrc = linkProductImage(item?.image?.path);
//    const [imageSrc, setImageSrc] = useState(linkProductImage(item?.image?.path));
//    const [hasAttemptedForceEnv, setHasAttemptedForceEnv] = useState(false);
//
//    // Handle image load error
//    const handleImageError = () => {
//        if (!hasAttemptedForceEnv) {
//            // First error: try with `isForceEnv = true`
//            setImageSrc(linkProductImage(item?.image?.path, true));
//            setHasAttemptedForceEnv(true);
//        } else {
//            // Second error: stop trying (fallback to default or leave broken image)
//            // console.error("Image failed to load: ", item?.image?.path);
//        }
//    };

    return (
        <StyledInventoryItem>
            <div
                className="wrapper"
                onClick={() =>
                    // history.push(`/inventory/${categoryId}/${subCategoryId}/${item?.id}`)
                    categoryId
                        ? history.push(
                              `/inventory/${item?.id}?categoryId=${categoryId}&subCategoryId=${subCategoryId}`
                          )
                        : history.push(`/inventory/${item?.id}`)
                }
            >
                <div className="wrapperImage">
                    <img
                        className="image"
                        src={imageSrc}
                        alt="inventory item"
                    />
                </div>
                <div className="wrapperContent">
                    <div className="title" style={{ whiteSpace: "pre-line" }}>
                        {item?.name}
                    </div>
                    <p className="subTitle">
                        <span className="icon">
                            <img src={layer} alt="icon" />
                        </span>
                        <span className="amount">{item?.amount}</span>
                    </p>
                    <div
                        className="myUrl"
                        onClick={() => window.open(item?.external_url, "_blank")}
                    >
                        {item?.external_url}
                    </div>
                </div>
            </div>
        </StyledInventoryItem>
    );
};
